<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Autorizzazioni</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="richiesta">
                    <div class="field_note">
                        <div class="title permission_title">
                            <span>GPS</span>
                            <span class="btn_test_action" @click="checkGeolocationPermission()">Richiedi permessi</span>
                        </div>
                        <div class="value">
                            <div v-if="loadingGpsPermission">
                                <span class="dot" v-for="(dot, index) in 3" :key="index" :style="{ animationDelay: index * 250 + 'ms' }"></span>
                            </div>
                            <div v-else>{{ coordinates.lat }}, {{ coordinates.lon }}</div>
                            {{ statoGps }}
                        </div>
                    </div>
                    <div class="field_note">
                        <div class="title permission_title">
                            <span>Fotocamera</span>
                            <span class="btn_test_action" @click="reloadCameraPermission()">Richiedi permessi</span>
                        </div>
                        <div class="value">
                            <div v-if="loadingCameraPermission">
                                <span class="dot" v-for="(dot, index) in 3" :key="index" :style="{ animationDelay: index * 250 + 'ms' }"></span>
                            </div>
                            <div v-else>
                                {{ statoCamera }}
                            </div>
                        </div>
                    </div>
                    <div class="field_ricevuta">
                        <div class="title">NFC</div>
                        <div class="value">
                            {{ permissions.nfc }}
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { defineComponent, ref } from "vue";

import { dateFormat } from "@/services/utils";

export default defineComponent({
    name: "ModalAutorizzazioni",
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup() {
        const loading = ref(false);

        /**
         * Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        const permissions = ref({
            camera: "unknown",
            geolocation: "unknown",
            nfc: "unknown",
        });

        /**
         *
         * ! GPS
         *
         */
        const coordinates = ref({
            lat: "-",
            lon: "-",
        });
        const loadingGpsPermission = ref(false);
        const geoOptions = {
            enableHighAccuracy: true,
            timeout: 5000, //max ms the device is allowed to take in order to return the position
            maximumAge: 0, //max ms of a possible cached position that is accetptable to return. 0 means no cached position
        };
        const statoGps = ref("");
        async function getCoordinates(options) {
            return new Promise((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        return resolve(position);
                    },
                    function(error) {
                        if (error.code === error.PERMISSION_DENIED) {
                            //openToast("GPS spento oppure hai negato l'autorizzazione alla geolocalizzazione.", "toast_danger");
                        } else {
                            // Si è verificato un altro tipo di errore durante la geolocalizzazione
                            //entrataTimbrata.value = false;
                            //openToast("Si è verificato un errore durante la geolocalizzazione.", "toast_danger");
                        }
                        return reject(error);
                    }
                )
            );
        }

        async function checkGeolocationPermission() {
            loadingGpsPermission.value = true;

            coordinates.value.lat = "-";
            coordinates.value.lon = "-";
            try {
                const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
                //console.log("geolocation; ", permissionStatus);
                permissions.value.geolocation = permissionStatus.state;
            } catch (error) {
                //console.log("ERROR: ", error);
                statoGps.value = "GPS spento o negata autorizzazione";
            }

            let pos = null;
            try {
                pos = await getCoordinates();
                //console.log(pos);
                coordinates.value.lat = pos.coords.latitude;
                coordinates.value.lon = pos.coords.latitude;
            } catch (error) {
                //console.log("ERROR: ", error);
                statoGps.value = "GPS spento o negata autorizzazione";
            } finally {
                loadingGpsPermission.value = false;
            }
        }
        checkGeolocationPermission();

        /**
         *
         * ! CAMERA PERMISSION
         *
         */
        const statoCamera = ref("-");
        const loadingCameraPermission = ref(false);
        async function checkCameraPermission() {
            loadingCameraPermission.value = true;

            const permissionStatus = await navigator.permissions.query({ name: "camera" });
            //console.log("camera; ", permissionStatus);
            permissions.value.camera = permissionStatus.state;

            if (permissionStatus.state === "granted") {
                statoCamera.value = "Concesso";
            } else if (permissionStatus.state === "prompt") {
                statoCamera.value = "Da richiedere";
            } else {
                statoCamera.value = "Negato";
            }

            loadingCameraPermission.value = false;
        }
        checkCameraPermission();

        async function reloadCameraPermission() {
            try {
                loadingCameraPermission.value = true;

                const stream = await navigator.mediaDevices.getUserMedia({ video: true });

                // Se la promise ha successo, significa che i permessi sono stati concessi
                //console.log("Permessi per la fotocamera concessi:", stream);
                statoCamera.value = "Concesso";
            } catch (error) {
                //console.log(error);
                // Se la promise viene rigettata, potrebbe essere perché i permessi sono stati rifiutati o l'utente non ha ancora fatto una scelta
                if (error.name === "NotAllowedError" || error.name === "NotFoundError") {
                    // Rifiutato o mai richiesto prima, chiedi nuovamente i permessi
                    const newStream = await navigator.mediaDevices.getUserMedia({ video: true });
                    //console.log(newStream);
                    //console.log("Nuovi permessi per la fotocamera concessi:", newStream);
                    statoCamera.value = "Concesso";
                } else {
                    // Altri tipi di errori
                    //console.error("Errore durante la richiesta dei permessi:", error);
                    statoCamera.value = "Errore / negato";
                }
            } finally {
                loadingCameraPermission.value = false;
            }
        }

        /**
         * NFC
         */
        function checkNFCPermission() {
            //console.log(navigator);
            if ("NDEFReader" in window) {
                navigator.permissions.query({ name: "nfc" }).then((permissionStatus) => {
                    permissions.value.nfc = permissionStatus.state;
                });
            } else {
                permissions.value.nfc = "Non rilevato";
            }
        }
        checkNFCPermission();

        return {
            loading,
            dateFormat,
            arrowBackOutline,
            closeModal,
            permissions,
            checkGeolocationPermission,
            statoGps,
            coordinates,
            statoCamera,
            loadingGpsPermission,
            loadingCameraPermission,
            checkCameraPermission,
            reloadCameraPermission,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

.description_title {
    color: var(--ion-color-primary, #3880ff) !important;
    margin: 10px 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

.richiesta {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.richiesta .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.richiesta .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
    border-bottom: 1px solid #e2e8f0;
}
.richiesta .field_ricevuta {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
}
.field .title,
.field_note .title,
.field_ricevuta .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_ricevuta .title {
    margin-bottom: 8px;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value,
.field_ricevuta .value {
    font-size: 14px;
}

.value .badge {
    font-size: 12px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.value .badge_success {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
    font-weight: bold;
}
.value .badge_danger {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: bold;
}
ion-button {
    --color: #ffffff;
}

.permission_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn_test_action {
    color: #ffffff;
    background-color: #086fa3;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 10px;
}
.dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #086fa3;
    border-radius: 50%;
    margin-left: 5px;
    animation: dotAnimation 1s infinite;
}

@keyframes dotAnimation {
    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(0);
    }
}
</style>
